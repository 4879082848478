<template>
    <div class="patientShareTemplate_container">
        <div class="patientShareTemplate_title global_text_content">
            <el-input
                ref="title"
                v-model="data.title"
                @input="changeTitle"
                placeholder="点此输入标题"
                type="textarea"
                @blur="showTitleProxy =  true"
            />
            <div class="global_text_count">{{getCount('title')}}</div>
            <div class="patientShareTemplate_title_proxy" @click="focusTitle" v-if="showTitleProxy">
                <div>
                    <span>{{getTitleMsg()}}</span>
                    <span class="patientShareTemplate_title_msg" v-if="data.title">{{getInputMsg()}}</span>
                </div>
            </div>
        </div>
        <div class="patientShareTemplate_content global_text_content">
            <el-input
                v-model="data.content"
                @input="changeContent"
                @blur="showContentProxy =  true"
                placeholder="点此输入正文"
                ref="content"
                type="textarea"
            />
            <div class="global_text_count global_text_count_content">{{getCount('content')}}</div>
            <div class="patientShareTemplate_content_proxy" @click="focusContent" v-if="showContentProxy">
                <div>
                    <span v-html="getContentMsg()"></span>
                </div>
            </div>
        </div>
        <div class="patientShareTemplate_images ">
            <DisplayImages :isEdit="true" @addFile="addFile" @removeFile="removeFile" :images="data.images" :meetId="meetId" type="病例" />
        </div>
    </div>
</template>
<script>
import { ElInput } from 'element-plus'
import CustomImage from '@/components/unit/customImage'
import DisplayImages from '@/components/unit/displayImages'
import {ElIcon} from 'element-plus';
import { Plus,Close } from '@element-plus/icons'
export default {
    name:"patientShareTemplate",
    props:{
        pageItem:{
            type:Object,
            default:()=>{
                return {}
            }
        },
        meetId:Number
    },
    components:{
        ElInput,
        CustomImage,
        DisplayImages,
        ElIcon,
        Close,
        Plus
    },
    data(){
        return {
            data:{
                title:"",
                content:"",
                images:[]
            },
            config:{
                msg:[
                    {
                        title:"患者基本信息",
                        content:"点此输入正文（可选）\n（年龄、孕产次、孕周、主诉、现病史）"
                    },
                    {
                        title:"病史简介",
                        content:"点此输入正文（可选）\n（既往史、个人史、家族史）"
                    },
                    {
                        title:"体格检查",
                        content:"点此输入正文（可选）\n（身高、体重、体温、脉搏、血压、其他）"
                    },
                    {
                        title:"产科检查",
                        content:"点此输入正文（可选）\n（宫高、腹围、胎心、宫缩、宫口、阴道出血、其他）"
                    },
                    {
                        title:"实验室检查",
                        content:"点此输入正文（可选）\n（血常规、尿常规、便常规、其他）"
                    },
                    {
                        title:"影像学检查",
                        content:"点此输入正文（可选）\n（胎儿超声、其他）"
                    },
                    {
                        title:"入院诊断",
                        content:"点此输入正文（可选）"
                    },
                    {
                        title:"诊疗经过",
                        content:"点此输入正文（可选）\n（治疗时间、治疗方法、治疗结果）"
                    },
                    {
                        title:"讨论",
                        content:"点此输入正文（可选）\n（分享诊疗过程中的疑难问题或总结思考）"
                    }
                ]
            },
            showTitleProxy:true,
            showContentProxy:true,
            inputMsg:['','',''],
            keys:['title','content','images']
        }
    },
    created(){
        console.log(this.pageItem)
        this.initData()
    },
    methods:{
      initData(dataKey,item){
            dataKey = dataKey || "class_content"
            this.keys.forEach((key,index)=>{
                if(key == 'images'){
                    this.data[key] = item ?? this.pageItem.content[index][dataKey].split(',').filter(x=>x!="")
                }else{
                    this.data[key] = item ??  this.pageItem.content[index][dataKey]
                }
            })
        },
        clearInfo(){
            this.initData('default_content','')
        },
        restore(){
            this.initData('default_content')
        },
        getInputMsg(){
            let content = this.inputMsg[this.pageItem.systemIndex-1];
            console.log(this.pageItem)
            return content ? `(${content})` : "(点击可修改标题)"
        },
        getTitleMsg(){
            let item = this.config.msg[this.pageItem.systemIndex-1];
            let space = this.pageItem.systemIndex <= 8 ? 0 : 1
            // let title = this.data.title || `${this.pageItem.systemIndex + space }、${item.title}`
            let title = this.data.title || "点击可修改标题"
            return title
        },
        getContentMsg(){
            let item = this.config.msg[this.pageItem.systemIndex-1];
            let content = this.data.content || `${item.content}`
            return content
        },
        getCount(key){
            let data = this.$tools.dealWithText(this.data[key],this.getCfg(key,'template_max'))
            return `${data.count}/${this.getCfg(key,'template_max')}`
        },
        changeTitle(){
            this.data.title = this.$tools.dealWithText(this.data.title,this.getCfg('title','template_max')).content
        },
        changeContent(){
            this.data.content = this.$tools.dealWithText(this.data.content,this.getCfg('content','template_max')).content
        },
        
        getCfg(key,attr){
            let index = this.keys.indexOf(key)
            return this.pageItem.content[index][attr]
        },
    
        getCfg(key,attr){
            let index = this.keys.indexOf(key)
            return this.pageItem.content[index][attr]
        },
        focusTitle(){
            this.showTitleProxy = false
            this.$refs.title.focus()
        },
        focusContent(){
            this.showContentProxy = false
            this.$refs.content.focus()
        },
        formatData(){
            let data = []
            let code = 200
            for(let index=0;index<this.keys.length;index++){
                let key = this.keys[index]
                if(key == "images"){
                    data.push(this.data[key].join(','))
                    continue;
                }
                data.push(this.data[key])
            }
            if(code != 400 && data.title == "" &&  this.data.content == "" && this.data.images.length == 0){
                this.$tips.error({text:"内容和图片请至少填写一项"})
                code =  400
            }
            data = data.map((item,index)=>{
                return {
                    class_content:item,
                    update_status:1,
                    id:this.pageItem.content[index].id
                }
            })
            return {code,data}
        },
        doEdit(){},
        addFile(urls){
            this.data.images = this.data.images.concat(urls)
        },
        removeFile(index){
            this.data.images.splice(index,1)
        },
        getData(callback){
            let data = this.formatData()
            callback(data)
        }
    }
}
</script>
<style>
.patientShareTemplate_container{
    height:100%;
    width:100%;
    display: flex;
    flex-direction: column;
}
.patientShareTemplate_title{ 
    background: linear-gradient(#80b25f,#88b769,#becf9c);
    height:16.4vw;
    position: relative;
}
.patientShareTemplate_title textarea{
    background:transparent;
    border:none;
    color:#fff;
}
.patientShareTemplate_title textarea::placeholder{
    color:#fff;
}
.patientShareTemplate_title  .el-input__count{
    background:transparent;
    color:#fff;
}

.patientShareTemplate_content textarea::placeholder{
    color:#000;
}
.patientShareTemplate_content textarea{
    height:22vw;
}
.patientShareTemplate_images{
    flex:1 0 0;
    overflow: hidden;
}
.patientShareTemplate_content{
    border-bottom:1px solid #e6e6e6;
    position: relative;
}
.patientShareTemplate_title_proxy,.patientShareTemplate_content_proxy{
    display: flex;
    align-items: center;
    width:100%;
    height:100%;    
    position: absolute;
    left:0;
    top:0;
    padding:0 4vw;
    z-index:2;
}
.patientShareTemplate_content_proxy{
    background:#fff;
    white-space: pre-wrap;
}
.patientShareTemplate_title_proxy{
    background: linear-gradient(#80b25f,#88b769,#becf9c);
    color:#fff;
}
.patientShareTemplate_title_msg{
    font-size:2.8vw;
}
</style>